import { useEffect, useState } from "react";

import { ThemeMode } from ".config/themes";

/**
 * useThemeMode
 */
const useThemeMode = (): [ThemeMode, () => void, boolean] => {
  const [themeMode, setThemeMode] = useState<ThemeMode>("light");
  const [mounted, setMounted] = useState(false);

  const setMode = (mode: ThemeMode) => {
    window.localStorage.setItem("theme", mode);
    setThemeMode(mode);
  };

  const toggleThemeMode = () => {
    themeMode === "light" ? setMode("dark") : setMode("light");
  };

  useEffect(() => {
    const getMediaQueryList = () =>
      window.matchMedia("(prefers-color-scheme: dark)");
    const prefersDarkMode = getMediaQueryList().matches;
    const localTheme = window.localStorage.getItem("theme") as ThemeMode;

    if (localTheme) {
      setThemeMode(localTheme);
    } else if (prefersDarkMode) {
      setThemeMode("dark");
    } else {
      setThemeMode("light");
    }

    const handleThemeMode = (event: MediaQueryListEvent) => {
      if (localTheme) {
        setThemeMode(localTheme);
      } else {
        setThemeMode(event.matches ? "dark" : "light");
      }
    };

    setMounted(true);

    getMediaQueryList().addEventListener("change", handleThemeMode);
    return () =>
      getMediaQueryList().removeEventListener("change", handleThemeMode);
  }, []);

  return [themeMode, toggleThemeMode, mounted];
};

export default useThemeMode;
