import React, { createContext } from "react";
import { ThemeProvider } from "styled-components";

import useThemeMode from ".state/hooks/useThemeMode";
import themes, { ThemeMode } from ".config/themes";

export const ThemeModeContext = createContext(
  {} as {
    themeMode: ThemeMode;
    toggleThemeMode: () => void;
  }
);

/**
 * ThemeManager
 */
const ThemeManager: React.FC = ({ children }) => {
  const [themeMode, toggleThemeMode, mounted] = useThemeMode();
  if (!mounted) return <div />;
  return (
    <ThemeModeContext.Provider value={{ themeMode, toggleThemeMode }}>
      <ThemeProvider theme={themes[themeMode]}>{children}</ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export default ThemeManager;
