import React from "react";

import Seo from "./src/components/meta/Seo";
import ThemeManager from "./src/state/context/ThemeManager";

export const wrapRootElement = ({ element }) => (
  <>
    <Seo />
    <ThemeManager>{element}</ThemeManager>
  </>
);
