import c from "./colors";

export default {
  accent: c.medium,
  border: c.medium,
  background: c.daintree,
  button_hover: c.secondary,
  button_text: c.light,
  button: c.primary,
  dropdown: c.primary,
  header: c.transparent,
  link_hover: c.secondary,
  link: c.primary,
  logo_hover: c.secondary,
  logo: c.primary,
  overlay: c.black,
  primary: c.primary,
  secondary: c.secondary,
  text: c.light,
  tt_slider: c.medium,
  tt_switch: c.light,
};
