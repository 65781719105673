import Helmet from "react-helmet";
import React from "react";

import {
  alternateName,
  contact,
  description,
  foundingDate,
  lang,
  links,
  logo,
  name,
  slogan,
  thumbnail,
  url,
} from ".data/meta";

const structuredData = `{
  "@context": "http://schema.org",
  "@type": "MusicGroup",
  "genre": ["Blues Rock", "Blues"],
  "name": "${name}",
  "alternateName": "${alternateName}",
  "description": "${description}",
  "foundingDate": "${foundingDate}",
  "url": "${url}",
  "logo": "${logo}",
  "image": "${thumbnail}",
  "contactPoint": [{
      "@type": "ContactPoint",
      "email": "${contact.email}",
      "telephone": "${contact.phone}",
      "contactType": "customer service"
  }],
  "sameAs": [
    "${links.facebook}",
    "${links.github}",
    "${links.soundcloud}",
    "${links.youtube}"
  ]  
}`;

/**
 * Seo
 */
const Seo: React.FC = () => {
  return (
    <Helmet defaultTitle={name} titleTemplate={`%s - ${slogan}`} title={name}>
      <meta name="description" content={description} />
      <meta name="image" content={thumbnail} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:title" content={name} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <script type="application/ld+json">{structuredData}</script>
      <html lang={lang} dir="ltr" />
      <link
        href="https://fonts.googleapis.com/css2?family=Notable&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default Seo;
