/**
 * Metadata
 */
module.exports = {
  name: "RamblerFive",
  alternateName: "Ramblers",
  description: "Wir sind die RamblerFive, eine Blues-Rock Band aus Regensburg.",
  foundingDate: "2021",
  url: "https://www.ramblerfive.de",
  logo: "https://www.ramblerfive.de/favicon.png",
  thumbnail: "https://www.ramblerfive.de/thumbnail.jpeg",
  contact: {
    email: "rhk@kapfberger.de",
    phone: "01234567890",
  },
  links: {
    facebook: "https://facebook.com",
    github: "https://github.com",
    soundcloud: "https://soundcloud.com",
    youtube: "https://www.youtube.com",
  },
  slogan: "Handmade Music",
  lang: "de",
  author: "Ralf Kapfberger",
  color: "#002130",
  googleAnalyticsID: "UA-XXXXXXXX-X",
};
