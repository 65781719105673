import dark from "./dark";
import light from "./light";

const themes = {
  dark,
  light,
};

export default themes;

export type ThemeMode = keyof typeof themes;

export type Theme = typeof light;
