const colors = {
  black: "#000",
  botticelli: "#d2e0e9",
  daintree: "#002130",
  dark: "#333",
  grey: "#666",
  light: "#ccc",
  medium: "#999",
  rust: "#a35d22",
  steelblue: "#4579a0",
  white: "#fff",
  transparent: "rgba(0,0,0,0.9)",
};

export default {
  ...colors,
  primary: colors.steelblue,
  secondary: colors.rust,
};
